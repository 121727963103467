import React from 'react';
import {images, data} from '../../constants';
import { SubHeading } from '../../components';

import './Laurels.css';

const AwardCard = ({award: {imgUrl, title, subtitle}}) => (
  <div className='app__laurels_awards-card'>
    <img src={imgUrl} alt="award" />
    <div className='app_laurels_award-card_content'>
      <p className='p__cormorant' style={{color: '#5f4a36', marginBottom: '20px'}}>{title}</p>
      <p className='p__opensans_about' >{subtitle}</p>
    </div>
  </div>
)

const Laurels = () => (
  <div className='app__bg app__wrapper section__padding' id='testimonials'>
    <div className='app__wrapper_inf0'>
      <div className='testimonials_subheading'>
        <SubHeading title="Sprawdź co sądzą inni" />
      </div>
      <h1 className='app__header-h2'>Opinie</h1>
      <div className='app__laurels_awards'>
        {data.awards.map((award) => <AwardCard award={award} key={award.title}/>)}
      </div>
      <button type='button' className='custom__button testimonials_btn'><a href='https://lubimyczytac.pl/autor/237498/natalia-k-palonek'>Przeczytaj więcej</a></button>
    </div>
  </div>
);

export default Laurels;
