import React from 'react';
import { useEffect } from 'react';
import images from '../../constants/images';
import AOS from 'aos';

import 'aos/dist/aos.css';
import './Header.css';


const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000});
  }, []) 
  return (
    <div className='app__header app__wrapper section__padding' id='home'>
      <div className='app__wrapper_info' data-aos="fade-down">
        <h1 className='app__header-h2'> Natalia K. </h1>
        <h1 className='app__header-h1'>Palonek</h1>
        <div className='app__chef-content' >
          <div className='app__chef-content_quote'>
            <img src={images.quote} alt="quote" />
            <p className='p__opensans'>Losing your way on a journey is unfortunate.</p>
          </div>
          <p className='p__opensans' style={{margin: '0 0 10px 0'}}>But, losing your reason for the journey is a fate more cruel.</p>
        </div>
        <button type='button' className='custom__button'><a href='#about'>Dowiedz się więcej</a></button>    
      </div>
      <div className='app__wrapper_img img_header' data-aos="fade-down">
        <img src={images.welcome2} alt="header img" />
      </div>
    </div>
  );
}
export default Header;
