import React from 'react';
import { FaFacebook, FaEnvelope, FaInstagram, FaLinkedin } from 'react-icons/fa';
import SubHeading from '../SubHeading/SubHeading';
import './Newsletter.css';
import { images } from '../../constants';

const Newsletter = () => (
  <div className="app__newsletter">
    <div className="app__newsletter-heading">
      
      <h1 className="app__header-h2">Masz pytania?</h1>
      <p className="p__opensans">Zapraszam do kontaktu!</p>
    </div>
    <div className="app__newsletter-input flex__center">
        <div className="app__footer-links_logo">
        <p className="p__opensans">Znajdziesz mnie pod linkami poniżej. Możesz skorzystać z emaila lub też portali społecznościowych.</p>
        <div className="app__footer-links_icons">
          <a href="mailto:natalialech94@gmail.com"><FaEnvelope /></a>
          <a href="https://www.facebook.com/profile.php?id=100083056974359"><FaFacebook /></a>
          <a href="https://www.instagram.com/nataliapalonek/"><FaInstagram /></a>
          <a href="https://www.linkedin.com/in/natalia-palonek-7771ab18b/"><FaLinkedin /></a>
        </div>
      </div>
    </div>
  </div>
);

export default Newsletter;