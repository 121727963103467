import React from 'react';
import images from '../../constants/images';

const SubHeadingWhite = ({title}) => (
  <div style={{marginBottom: '1rem'}}>
    <div><p className='p__opensans4'>{title}</p></div>  
    <div><img src={images.separatorwhite} alt='separatorwhite' className='separator__img'/></div>
  </div>
);

export default SubHeadingWhite;
