import React, { useState, useEffect } from 'react';
import { images, data } from '../../constants';
import { SubHeadingWhite, MenuItem } from '../../components';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './SpecialMenu.css';

const SpecialMenu = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [cart, setCart] = useState([]);

  const books = [
    {
      title: "Dziewczyna do towarzystwa",
      description: "Alice miała gotowy plan na życie, ale nie wzięła pod uwagę, że życie pisze własne scenariusze. Nagła utrata pracy i pogarszająca się sytuacja finansowa sprawiają, że postanawia zatrudnić się jako dziewczyna, która ma zapewniać dobrą zabawę i towarzystwo. Ale to dopiero początek niespodziewanych zdarzeń, bowiem na jej drodze pojawia się wyjątkowy mężczyzna. Spencer, młody aktor przytłoczony życiem w cieniu ojca, wciąga Alice do światka show-biznesu i… swojej poplątanej przeszłości. Ustawiane randki, seks na telefon, narkotykowe imprezy i nieustanna presja nie sprzyjają rodzącemu się powoli między nimi zaufaniu. Ile będą musieli poświęcić, aby wyrwać się z gęstej sieci kłamstw i zacząć żyć w zgodzie ze swoimi uczuciami?",
      image: images.DDT,
      shopLink: 'https://lubimyczytac.pl/ksiazka/5039263/dziewczyna-do-towarzystwa'
    },
    {
      title: "Nieustanna gra pozorów",
      description: "Gdy wszystkie karty zostały odsłonięte, wychodzi na jaw, że gra pozorów dopiero się rozpoczęła. Spencer po przedawkowaniu narkotyków wraca do zdrowia, jednak ten przykry incydent negatywnie wpływa na jego pamięć. Alice ponownie wkracza w rolę asystentki, ale tym razem wie zdecydowanie więcej o osobach, którymi wraz ze Spencem muszą się otaczać. Pozornie niewinna praca może szybko przerodzić się w koszmar i położyć na szali nie tylko ich życie, ale również bezpieczeństwo ich bliskich. Para wyciąga kolejne asy szokujących powiązań, a konsekwencje ich wyborów rozpoczynają lawinę nieprzewidzianych zdarzeń. Czy prawda, która wyjdzie na jaw, jest warta swojej ceny? Czy następstwa podjętych czynów będą końcem ich związku, czy może pogłębią wystawiane na próbę uczucie?",
      image: images.NGP,
      shopLink: 'https://lubimyczytac.pl/ksiazka/5082092/nieustanna-gra-pozorow'
    },
    {
      title: "Cicha Aria",
      description: "Gdy słyszysz przeraźliwy krzyk, masz dwa wyjścia: ruszyć na pomoc lub uciekać przed zagrożeniem. Nataniel wybrał pierwszą opcję, nie wiedząc, że ocalenie pewnej dziewczyny z szalejącego żywiołu to otwarcie ran, które prawie się zagoiły. Szybko się okazuje, że nic tego wieczoru nie jest przypadkowe: ani data, ani dziewczyna, ani tym bardziej jej motywy. Wydarzenia uruchamiają zegar, którego wskazówki nieustannie odliczają czas do uratowania czyjegoś życia. Czy jesteś w stanie poświęcić dobre imię, by dowieść prawdy? Czy walka z przeciwnościami losu to determinacja, czy balansowanie na granicy szaleństwa? Jednak najważniejsze pytanie brzmi: czy udźwigniesz ciężar prawdy, gdy już ją odkryjesz?",
      image: images.CA,
      shopLink: 'https://lubimyczytac.pl/ksiazka/5101862/cicha-aria'
    }
  ];

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? -1 : index);
  };

  const addToCart = (index) => {
    if (!cart.includes(index)) {
      setCart([...cart, index]);
    }
  };

  useEffect(() => {
    AOS.init({duration: 2000, disable: 'mobile'});
  }, []) 

  return (
    <div className='app__specialMenu flex__center section__padding' id='books'>
      <div className='app__specialMenu-title'>
        <SubHeadingWhite title="Zapoznaj się z moją twórczością"/>
        <h1 className='app__header-h2'>
          Publikacje
        </h1>
      </div>
      <div className='app__specialMenu-menu'>
        {books.map((book, index) => (
          <div key={index} className='app__specialMenu-menu_coctails flex__center'>
            <div className='app__specialMenu-menu_items' data-aos="flip-left">
              <img src={book.image} alt={book.title} />
              <p className='p__opensans_about'>{expandedIndex === index ? book.description : book.description.slice(0, 250) + '...'}</p>
              <button type='button' className='custom__button' onClick={() => handleExpand(index)}>
                {expandedIndex === index ? 'Mniej' : 'Więcej'}
              </button>
              <a href={book.shopLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faShoppingCart} className='cart-icon' style={{ fontSize: '24px' }} onClick={() => addToCart(index)} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SpecialMenu;
