import React from 'react';

import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Sprawdź</h1>
        <ul className='app__foot_links'>
          <li className='p__opensans_footer'><a href='#about'>O mnie</a></li>
          <li className='p__opensans_footer'><a href='#books'>Książki</a></li>
          <li className='p__opensans_footer'><a href='#read'>Przeczytaj</a></li>
          <li className='p__opensans_footer'><a href='#testimonials'>Opinie</a></li>
          <li className='p__opensans_footer'><a href='#books'>Kup</a></li>
          <li className='p__opensans_footer'><a href='#gallery'>Instagram</a></li>
      </ul>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.logo_black} alt="footer_logo" />
        {/* <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p> */}
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Linki</h1>
        <ul className='app__foot_links'>
          <li className='p__opensans_footer'><a href='#'>Materiały do prasy</a></li>
          <li className='p__opensans_footer'><a href='#menu'>FAQ</a></li>
          <li className='p__opensans_footer'><a href='#menu'>Polityka cookies</a></li>
      </ul>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans_about">2024 Natalia Palonek. All Rights reserved.</p>
    </div>

  </div>
);

export default Footer;