import React, { useState } from 'react';
import { useEffect } from 'react';
import images from '../../constants/images';
import { SubHeading } from '../../components';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Read.css';

const Read = () => {
  useEffect(() => {
    AOS.init({ duration: 2000, disable: 'mobile' });
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className='app__bg app__wrapper section__padding' id='read'>
      <div className='app__wrapper_img app__wrapper_img_reverse' data-aos="fade-right">
        <img src={images.read_bg2} alt="chef" />
      </div>
      <div className='app__wrapper_info' data-aos="fade-left">
        <SubHeading title="Pierwsze rozdziały moich książek" />
        <h1 className='app__header-h2'>Przeczytaj</h1>

        <div className="accordion">
          <div className={`accordion__item ${activeIndex === 0 ? 'active' : ''}`} onClick={() => handleAccordionClick(0)}>
            <div className="accordion__title p__opensans_about">"DZIEWCZYNA DO TOWARZYSTWA"</div>
            <div className="accordion__content p__opensans_about">
                <h2>ALICE </h2><br/>
                <p>
                – Szkoda, że nie mogę z tobą dłużej zostać – mówiła dość niewyraźnie, szczotkując zęby
                jedną dłonią, a drugą wpychając nie do końca wyprasowaną bluzkę w spódnicę.
                – Łóżko jeszcze ciepłe, możesz wrócić. – Matt poklepał dłonią miejsce obok siebie.
                Odwróciła się, oparła się o zlew i patrząc przez uchylone drzwi do sypialni, spojrzała na
                niego. Spod miękkiej kołdry można było dostrzec tylko głowę i bujne, brązowe włosy, które
                mimo braku wiatru wyglądały, jakby przeszło przez nie tornado. Każdy włos stał w inną
                stronę, ale uznała, że ta fryzura na swój sposób bardzo do niego pasuje. Z chęcią położyłaby
                się obok i zapomniała na chwilę o otaczającej ją rzeczywistości, ale nie dziś. Dziś nie może
                się spóźnić.
                – Kusisz, i to bardzo. – Uśmiechnęła się i schyliła, by upiąć swoje czarne włosy w
                niezdarnego koka. Ponownie wróciła do mycia zębów. Kiedy odkładała szczoteczkę do
                odpowiedniego kubeczka w łazience, zauważyła w niej nową.
                – Już mnie zdradzasz? – Uniosła brew.
                – Słucham? – Matt wydał się trochę spięty i momentalnie oparł się o zagłówek łóżka.
                – Mówię o nowej szczoteczce do zębów. Wy, mężczyźni, nie potraficie wyrzucać
                swoich starych rzeczy do kosza.
                – A, to… – Przewrócił oczami. – Wyrzuć do kosza.
                – Gdyby była różowa, to bym się zaniepokoiła. – Puściła mu oczko i wyrzuciła czarną
                szczoteczkę do kosza.
                – Stresujesz się? – Matt zmienił temat. – Może kawy?
                Postanowił wyprostować kończyny i w samych bokserkach pomknął do kuchni
                nastawić wodę. Czując chłód na skórze, splątał dłonie na klatce piersiowej i nieco się kulił,
                czekając aż woda w czajniku zacznie wrzeć.
                – Czy się stresuję? Dobre pytanie. Przedłużenie umowy powinno odbyć się bez żadnych
                niespodzianek. Przez ostatni czas doceniali moją pracę i nikt nie mówił, żeby coś było nie tak.
                Liczę, że dzisiejsza rozmowa przyniesie mi nowe stanowisko i zakończę proces parzenia oraz
                noszenia kawy.
                Spojrzała na swoje odbicie w lustrze, upewniając się, że wygląda w miarę wyjściowo, a
                jej ubiór sugeruje dyskretną, ale luźną elegancję z dodatkiem niedbałości w postaci
                niestarannie upiętego koka na głowie.
                – Jak zawsze seksownie. – Podał jej kubek z gorącą kawą i pocałował w czoło.

                – Jej, naprawdę marzę, by tu z tobą zostać i pozwolić ci dalej mnie rozpieszczać. –
                Upiła łyk. – Od razu lepiej.
                – Nie ma tak dobrze, ale za to, jak wrócisz, ja tu będę czekać na ciebie w samej
                bieliźnie albo i bez niej. – Na jego twarzy rysował się ten zadziorny uśmieszek, który
                pokochała od pierwszego spotkania.
                – Mężczyzna marzenie. – Przewróciła oczami, a w jej głosie można było wyczuć
                sarkazm.
                – Będziesz o standardowej porze? – mówiąc to, skierował się w stronę łóżka i w ciągu
                kilku sekund leżał już komfortowo przykryty kołdrą pod sam nos. – No, co? Jest zimno i
                zdecydowanie za wcześnie.
                – Tak, będę o tej samej porze jak zazwyczaj. – Spojrzała na wyświetlacz telefonu. –
                Cholera, jak zawsze nie zdążę na metro. – W pośpiechu założyła czarne botki, porwała
                torebkę z fotela i udała się w stronę wyjścia. – Pa! – krzyknęła, zatrzaskując za sobą drzwi.
                Kliknęła przycisk przywołujący windę, ale widząc, że jest dopiero na pierwszym
                piętrze, postanowiła zbiec po schodach ewakuacyjnych. Przebiegła przed rozsuwane drzwi w
                holu, potrącając matkę prowadzącą dziecko, i wybiegła na ulicę, kierując się wprost do linii
                metra.
                – Przepraszam! – krzyknęła do kogoś, biegnąc już przez ulicę.

                ***

                Kiedy dotarła do pracy, skierowała się w stronę swojego biurka i od razu zauważyła
                napiętą atmosferę panującą w sali konferencyjnej. Jej szef wyglądał na zdenerwowanego;
                krzycząc jakieś frazesy, mocno gestykulował. Cała reszta siedziała raczej przestraszona i
                skupiona na blacie stołu lub na ekranie komputera, nie patrząc w ogóle w stronę swojego
                przełożonego. Przez chwilę cieszyła się w duchu, że jest tylko zwykłą stażystką obarczaną
                zadaniami, które raczej trudno zepsuć, ale w końcu kserowanie, donoszenie kawy, wycinanie
                etykietek z imionami ważnych ludzi oraz odbieranie telefonów miały zmienić się na pisanie
                maili i całą resztę, którą wykonują ludzie na stanowisku juniorskim.
                – Cześć. – Włączyła swój komputer. – Co się dzieje w konferencyjnej? – zapytała
                koleżanki, zajmując miejsce obok jej biurka.
                – Nie wiem, Alice, ale nie zapowiada się ciekawie. Chyba straciliśmy jakiegoś ważnego
                klienta. Zresztą, kto tam wie; nam, stażystom, nie jest dane dojść do głosu, co nie? – Annie
                tylko spojrzała na nią porozumiewawczo znad czarnych okularów.
                – Może dzisiaj coś się wyklaruje w sprawie naszej dalszej kariery w C&amp;C? – Usiadła na
                krześle i zaczęła przeglądać maile, a raczej zadania do wykonania od asystentki szefa. –

                Drukarka na drugim piętrze znów nie działa. HR zdecydowanie za dużo drukują. –
                Roześmiała się. – Idziesz ze mną sprawdzić?
                – W sumie nie mam nic lepszego obecnie do zrobienia, więc ci potowarzyszę.
                Skierowały się do windy, specjalnie obierając trasę obok sali konferencyjnej. Czekając,
                dyskretnie zerkały, co dzieje się za szklanymi drzwiami od pomieszczenia, gdzie aktualnie
                trwało zebranie. Jedyne, co dostrzegły, to przełożony, który podtrzymywał głowę dłońmi,
                nerwowo się po niej drapiąc.
                – Ciekawe, czy ta plotka z utratą klienta to prawda? – zapytała zaciekawiona Annie,
                ponownie naciskając przycisk windy. – Cokolwiek by się nie stało, mam nadzieję, że sytuacja
                ta nie wpłynie na nasze przyszłe umowy – stwierdziła, patrząc, jak drzwi winy powoli się
                rozsuwają.
                – O której masz rozmowę?
                – O dwunastej. – Annie poprawiła okulary na czubku nosa. – A ty?
                – Też. Może dobre nowiny chodzą parami?
                – Zazdroszczę takiego optymistycznego myślenia.
                Wydawało się jej, że do godziny dwunastej czas płynął bardzo powoli. Na dodatek
                zapomniała podładować wcześniej baterię telefonu, więc jej smartfon w trakcie pracy się
                rozładował. Musiała czekać, aż podłączy go do ładowania w domu. Zaczęła odczuwać
                niepokój, bo dziś w biurze panowała nerwowa atmosfera. Wszyscy wyglądali na zabieganych
                i roztargnionych. Nikt nic nie mówił. Słychać za to było uderzanie czubków palców o
                klawiatury i nieustanne klikanie myszki.
                Zaczęła się zastanawiać, co jeśli jej umowa tak po prostu wygaśnie. Jak opłaci swoje
                studia? Przecież od października powinna wprowadzić się do Matta. Ta niepewność budziła w
                niej stres. Stres, którego wolałaby w tej chwili nie czuć. Dlatego tę negatywną myśl, że coś
                może pójść nie tak, odpychała daleko na drugi plan. Tak daleko, że dopiero po chwili
                zorientowała się, jak mocno zacisnęła swoją dłoń. W jej wnętrzu powstały ślady wbijanych
                paznokci.
                – Alice, Annie – z zamyślenia wyrwał ją męski głos – proszę za mną.
                Odsunęły się od biurka niczym poparzone i skierowały się za młodym mężczyzną.
                Zaprosił je do małej salki, gdzie zazwyczaj przeprowadzane są rozmowy rekrutacyjne.
                – Usiądźcie, proszę. Nie zajmę wam dużo czasu. Jestem Chris, wysłannik z HR-ów –
                zażartował drobnej postawy mężczyzna. – Wyznaczono wam na dziś rozmowę z naszym
                uroczym szefem, ale niestety, jak mogłyście zauważyć, sytuacja w biurze jest dynamiczna i
                wiele się dzieje. Przechodząc do konkretów… Wynikła niekorzystna sytuacja dla naszego

                pracodawcy, w związku z czym zostaną obcięte pewne etaty. Na tę chwilę nasza firma nie
                może zagwarantować wam dalszego zatrudnienia ani przedłużenia stażu.
                – Co!? – Alice nie potrafiła ugryźć się w język, a raczej słowo to uciekło jej z ust, zanim
                zdążyła je przeprocesować.
                – Jest mi naprawdę przykro. – Nie, nie było mu przykro, to tylko tekst, który musiał
                zostać przez niego wypowiedziany. – Jesteście bystre i gdyby nie aktualny stan rzeczy, na
                pewno zostałybyście w C&amp;C. Na pocieszenie mogę wam powiedzieć, że dziś rano wasze
                umowy jeszcze były u mnie na stole.
                – I to ma być pocieszenie? Nasze umowy były, ale w ciągu dnia zdążyliście się
                rozmyślić – kontynuowała Alice.
                – Jeśli macie jakieś zażalenia, możemy porozmawiać z kierownikiem HR-ów. Niestety
                z doświadczenia wiem, że nie powie wam nic innego niż to, co ja już wam przedstawiłem.
                Przykro mi, ale nie jesteście dziś jedynymi osobami, które odbędą taką rozmowę. Potraktujcie
                to jak świeży start. Niektórzy tutaj mają już rodzinę, wy możecie zacząć zupełnie od nowa.
                – Daj spokój, Chris, ta twoja produkcja nic nieznaczących słów jest zbędna – wtrąciła
                się Annie wyraźnie poirytowana, po czym wstała z fotela. – Może nie mamy rodzin, ale za
                coś też musimy żyć. Nie jemy przecież powietrza. Na pewno nie polecę nikomu tej firmy.
                Strata kilku miesięcy.
                – Możemy napisać list polecający – stwierdził Chris, tak jakby ten list miał naprawić
                całą sytuację.
                – Nie, dzięki – odparła tym razem Alice.
                – Skoro już skończyliśmy, jesteście wolne. Zabierzcie swoje rzeczy i zostawcie puste
                półki.
                – Jak profesjonalnie! – zakpiła Annie, wychodząc ze szklanego pomieszczenia i nawet
                nie patrząc w stronę Chrisa.
                Alice za to poczuła uderzenie ciepła. Jeszcze nie do końca zdawała sobie sprawę ze
                stanu rzeczy. Mknęła jak cień ze spuszczoną głową za Annie, zastanawiając się, co powinna
                zrobić.
                – Annie, i co teraz? – zapytała.
                – Pakujemy swoje notesy, długopisy, zdajemy kartę wstępu i, jak to określił nasz
                kolega, zaczynamy na świeżo.
                – Jak spłacimy studia? Jak opłacimy mieszkanie? Nie mam do kogo zwrócić się o
                pożyczkę. – Alice nie rozumiała, jak Annie może być taka spokojna w obecnej sytuacji.
                Zachowywała się tak, jakby strata pracy jej nie dotyczyła.

                – Znajdziesz inną pracę – odparła bez wzruszenia Annie, pakując do swojej torebki
                kilka firmowych długopisów.
                – Do innej pracy? Pójdę na kasę do marketu? Tam nie szanują grafiku studenta. Poza
                tym pensja jest znacznie niższa. – Alice poczuła, jak łzy napływają jej do oczu, chociaż tak
                bardzo pragnęła je zatrzymać. Lubiła kreować swój wizerunek twardej kobiety, dlatego łzy w
                miejscu pracy były w jej odczuciu niedopuszczalne.
                Annie spojrzała na nią przez okulary, a właściwie zmierzyła ją wzrokiem od góry do
                dołu.
                – Słuchaj, Alice, jeśli jest faktycznie tak źle, jak mówisz, napisz do mnie wieczorem, a
                ogarnę nam jakieś dodatkowe zlecenie. Tylko zachowaj to dla siebie.
                W drodze powrotnej do mieszkania Matta czuła się mocno przygnębiona. Ten dzień
                poszedł zupełnie nie tak, jak powinien. Nie mogła pozbierać myśli ani ułożyć ich w jakąś
                logiczną całość. Praca w C&amp;C miała być trampoliną do jej przyszłego sukcesu, a okazała się
                totalnym niewypałem i stratą czasu. Najgorszy był fakt, że z dnia na dzień dopływ gotówki
                się skończył, a kolejna rata na studia musiała zostać wkrótce wpłacona. W ograniczeniu
                kosztów na życie miała jej pomóc przeprowadzka do Matta. Dzięki temu mogłaby skończyć z
                koszmarem mieszkania w akademiku z jedną łazienką na pięć osób, nie wspominając już o
                braku prywatności. Kolejną z możliwych opcji byłby powrót do domu, co przez chwilę nawet
                rozważała. Jednak tak szybko, jak pomyślała o ponownym zamieszkaniu w małym
                miasteczku, w którym nie ma żadnych perspektyw związanych z karierą w korporacji, tak
                samo szybko odsunęła tę myśl daleko w ciemny kąt umysłu.
                Gdy dotarła do mieszkania Matta, zegarek wskazywał kilka minut przed godziną drugą.
                Totalnie załamana pchnęła drzwi do środka, które jak zawsze były otwarte. Torebkę, z której
                wystawały zeszyty z logiem C&amp;C i kilka długopisów, rzuciła na półkę i kiedy już miała się
                rzucić w ramiona ukochanego, i poczuć ulgę po trudnym dniu, z jej ust wyrwały się
                następujące słowa:
                – CO, DO CHOLERY!? – Kompletnie osłupiała.
                – Alice? Co ty tutaj… – Matt, zaskoczony obecnością dziewczyny, próbował nałożyć na
                siebie bieliznę.
                – JAJA SOBIE ROBISZ!? – wrzasnęła, choć zrozumiała, że dobór słów wypadł mocno
                niekorzystnie, biorąc pod uwagę fakt, że obok Matta w łóżku był równie zszokowany
                mężczyzna.
                – Chciałem ci powiedzieć, ale czas wydawał się nieodpowiedni.

                – Czas nieodpowiedni!? Miałam się do ciebie wprowadzić! Wiesz co? Daruj sobie!
                Czemu nie zerwałeś ze mną, skoro ewidentnie pociąga cię ktoś inny?
                – To nie do końca tak! – krzyczał Matt, a ona nie potrafiła opisać jego miny, która
                wyrażała coś pomiędzy zażenowaniem a wstydem.
                – O mój Boże! Wychodzę.
                Zaczęła iść w stronę wyjścia, gdy przypomniała sobie, że potrzebuje ładowarki do
                telefonu. Wróciła po nią, nie patrząc ani na Matta, ani na jego kolegę. Wyciągnęła ją z półki i
                wyszła, zatrzaskując za sobą drzwi tak mocno, jak tylko mogła. Skierowała się w stronę
                schodów przeciwpożarowych i gdzieś między drugim a pierwszym piętrem zatrzymała się, by
                usiąść i uwolnić z siebie emocje. Łzy lały się jak rzeka, a ona nie miała sił, by je zatrzymać.
                Schowała głowę w nogach i modliła się, by nikt teraz nie przechodził, aby mogła się
                pozbierać. Tak dużo rzeczy się dziś wydarzyło, że nie mogła uwierzyć, że to jej życie, które
                zazwyczaj było dość starannie zaplanowane.
                Po kilkunastu minutach przetarła rękawem nos i oczy. Zapewne miała rozmazany
                makijaż na całej twarzy, ale to teraz nie było istotne. Zdała sobie sprawę, że płakanie na
                schodach w tym momencie nie pomoże jej ruszyć z miejsca. Zauważyła gniazdko, więc
                postanowiła podładować telefon i wtedy przypomniała sobie o Annie. Niezależnie od tego,
                jaką pracę dodatkową może jej zaoferować, chyba nie może być już gorzej.
                Hej, tu Alice. Chętnie załapię się na tę dodatkową fuchę.
                Wcisnęła „Wyślij”.</p></div>
          </div>

          <div className={`accordion__item ${activeIndex === 1 ? 'active' : ''}`} onClick={() => handleAccordionClick(1)}>
            <div className="accordion__title p__opensans_about">"NIEUSTANNA GRA POZORÓW"</div>
            <div className="accordion__content p__opensans_about">
              <h2>Rozdział 1.</h2><br/>
              <p>W zębach trzymał kawałek materiału, którego drugim końcem owinął rękę tuż nad łokciem.
              Przesunął głowę, tym samym pociągając za szmatkę, dzięki czemu mocnej się zacisnęła, a żyły
              uwidoczniły. Spencer patrzył na strzykawkę wypełnioną miksturą, którą przed chwilą
              przyrządził. Naprawdę był na dobrej drodze; jeszcze nie tak dawno wierzył, że narkotyki ma
              za sobą. „Życie bywa przewrotne” – stwierdził i parsknął pod nosem.
              Wbił igłę w skórę i wypluł końcówkę prowizorycznej opaski uciskowej. Patrzył, jak
              substancja ze strzykawki wpływa prosto do jego żył. Czuł, jak powoli rozchodzi się po jego
              organizmie, i przypomniał sobie znajome uczucie. Nadal pamiętał, jakie to zbawienne. Rzucił
              pustą strzykawkę i opadł na łóżko, czekając na błogą falę zapomnienia i ukojenia.
              Spencer nie miał zamiaru rozpamiętywać tego, co się stało, choć został oszukany przez
              najbliższych. Najpierw jego ojciec, który zatuszował morderstwo niewygodnej mu osoby, a
              równocześnie pierwszej miłości syna. Potem Alice, która pod przykrywką asystentki tak
              naprawdę grała na jego uczuciach i robiła wszystko dla własnego zysku. Mark, przyjaciel,
              który dopuścił się molestowania na osobie nieletniej i jeszcze agent, Jeremi, który pomógł
              zamieść sprawę pod dywan i pozbył się osób, które mogłyby puścić parę z ust. Brzmiało jak
              bardzo tandetny film. Dlatego też Spencer nie widział sensu pozostania w tym miejscu –
              wszyscy byli przy nim dla własnego dobra, i to tego materialnego, nie dla przyjaźni, miłości
              czy troski. Gdy dowiedział się prawdy, w jego głowie zrobiło się naprawdę mrocznie. Życie
              dla tych ludzi i na tej planecie nie miało sensu. Nic dziwnego, że zdecydował się zadzwonić do
              osoby, która nigdy nie zawiodła go w potrzebie, czyli swojego dilera.
              Uśmiechnął się do siebie pod nosem, gdy narkotyki sprawiły, że ból odszedł. Oddech
              zwolnił, nagle wszystko stało się zupełnie inne. Łza spłynęła mu po policzku, nie czuł już
              przerażenia ani smutku, lecz radość, ukojenie i spokój. Wszystko wokół niego się wyciszyło.
              Pozostał sam z pustymi myślami, ale o to mu właśnie chodziło – o wyczyszczenie własnej
              pamięci. Zagłuszył ten dramat, którym było jego własne życie. Wreszcie pojawiło się uczucie
              relaksu. Wzrok wszedł w inny wymiar, kolory nagle wyblakły, pokój robił się coraz ciaśniejszy,
              jakby ściany niebezpiecznie zbliżały się do niego, a łóżko, na którym leżał, zaczęło go wsysać.
              Nastała niebezpieczna ciemność. Czy się jej bał? Może tylko przez chwilę. Wiedział, co robi,
              kiedy zdecydował się na wstrzyknięcie takiej ilości substancji. Ukojenie nadeszło, a wraz z
              nim święty spokój.</p><br/>

              <h2>ALICE</h2><br/>

              <p>–Zabiłaś go… – usłyszała szept, a po jej skórze przeszedł dreszcz.
              – To twoja wina… – drwił z niej inny głos.
              – Halo! Czy jest tu ktoś!? – zawołała i odwróciła się spanikowana.
              Nic nie widziała, otaczała ją ciemność. Wiedziała jednak, że w pomieszczeniu nie jest
              sama. Coś szturchnęło jej bark, przez co upadła na kolana. Czuła, jak ręce jej drżą, gdy
              próbowała się podnieść. Znów coś ją pchnęło, ale tym razem nie mogła wstać.
              – Usmażysz się w piekle. – Głos ciągle jej towarzyszył.
              Nagle poczuła, że nie może oddychać. Chwyciła się za gardło, próbując zdjąć z szyi
              coś niewidocznego.
              – Jesteś następna – wyszydził kolejny głos.
              Alice otworzyła oczy, oddychając ciężko. Była przerażona, a po plecach i po czole
              spływał jej pot. Gdy uświadomiła sobie, że scena sprzed chwili to tylko zły sen, opadła do
              tyłu i podparła się na łokciach. Próbowała się uspokoić. Koszmary stały się jej codziennością;
              wiedziała, że to dręczące ją wyrzuty sumienia związane z tym, co miało miejsce po premierze
              serialu.
              Wstała z łóżka i pokierowała się do łazienki. Małe lampki przy podłodze, rzucając
              delikatną poświatę, wskazywały jej kierunek. Nie potrafiła już spać w ciemności, bo sny,
              które ją co noc nawiedzały, sprawiły, że ciemność stała się jej głównym lękiem. Odkręciła
              wodę i zanurzyła dłonie w lodowatym strumieniu, a potem przyłożyła je do twarzy. Spojrzała
              w lustro. Sine wory pod oczami tylko przypominały jej, że ma problem ze snem i najwyższa
              pora coś z tym zrobić. Kolejny przypływ uczucia bezsilności sprawił, że osunęła się na
              podłogę i zaczęła płakać. Siedziała tak skulona dłuższą chwilę, uświadamiając sobie, w jak
              bardzo złym jest położeniu, aż z zamyślenia wyrwał ją dźwięk telefonu. Zerwała się na równe
              nogi i pomknęła do sypialni, gdzie w łóżku czekał na nią smartfon. Alice ustawiła
              przeglądarkę w taki sposób, żeby wyłapywała słowa kluczowe i sygnalizowała od razu, gdy w
              sieci pojawi się jakikolwiek przeciek o Spencerze, choćby najdrobniejsza informacja.
              Kliknęła w link prowadzący do artykułu, którego tytuł brzmiał: Dobre wiadomości o stanie
              Spencera Tremblaya.

              Po prawie dwóch tygodniach od felernego przedawkowania ze szpitala Martina Luthera
              Kinga nadciągają dobre wieści. Syn oscarowego aktora wybudził się ze śpiączki

              farmakologicznej. Szczegóły nie są nam jeszcze znane, ale dla młodego Tremblaya to na
              pewno dobry znak. Jedno jest pewne: czeka go długa droga do zdrowia.

              Alice odruchowo spojrzała na komentarze pod artykułem. Wylewała się z nich sama
              nienawiść, ludzie życzyli Spencerowi śmierci, pisząc, że jest kolejną rozkapryszoną
              gwiazdką. Jeszcze pół roku temu, czytając ten artykuł, pewnie pomyślałaby podobnie.
              „Przecież ten chłopak ma wszystko, nudno mu, że bawi się narkotykami?” Teraz jednak
              wiedziała, że prawda jest zupełnie inna. Spencera złamały różne wydarzenia, ale najbardziej
              skrzywdzili go ojciec i jego własny agent.</p><br/>

              <h2>SPENCER</h2><br/>

              <p>Gdy otworzył oczy, wszystko w pomieszczeniu było rozmazane. Przez dłuższą chwilę
              mrugał, by złapać ostrość. Pomyślał, że jeśli to kac, to chyba w życiu takiego nie miał. Do
              jego uszu zaczęły docierać różne szmery i dziwne odgłosy, których nie rozpoznawał, choć
              brzmiały trochę znajomo. Po kilku sekundach zaczął w końcu widzieć wyraźniej, poczuł też
              ogromny ból w żołądku. Przechylił się na bok i zwymiotował na podłogę. Gdy z powrotem
              położył się na plecach, w głowie zaczęło mu wirować. Co jest, do cholery? Maszyny
              piszczały coraz głośniej, a Spencer miał wrażenie, że głowa zaraz mu eksploduje od tego
              głupiego dźwięku… Pik, pik, pik.
              – Pokój B34! – Usłyszał z oddali.
              Gdzie ja, kurwa, jestem?
              Próbował coś powiedzieć, ale udało mu się wydusić tylko jakieś zniekształcone słowa.
              Przeraził się, zwłaszcza że systematyczne „pik, pik, pik” zmieniło się nagle w jednostajne,
              przeraźliwe piszczenie. Ktoś dotknął jego dłoni.
              – Spokojnie, Spencer, podamy ci środki uspakajające.
              Co? Jakie środki?
              I znowu niezrozumiałe słowo wyszło z jego ust. Ktoś wstrzyknął mu do żyły jakąś
              substancję – czuł, jak ciepło wędruje wewnątrz jego ręki, a następnie rozpływa się po całym
              ciele. Ogarnęło go przyjemne uczucie, a zaraz po nim senność.
              Nie umiał określić, jak długo spał lub był nieprzytomny, ale gdy ponownie otworzył
              oczy, widział ostrzej. Pierwsze, co zobaczył, to biały sufit i zrozumiał, że nie jest u siebie w
              domu. Delikatnie przekręcił głowę w prawą stronę i spojrzał na kroplówkę, która wisiała przy
              jego łóżku. Zmarszczył brwi, nie rozumiejąc, co tak właściwie się dzieje. Wolną dłonią
              dotknął kołdry; zdecydowanie nie była to ta, pod którą sypiał we własnym łóżku. Podparł się
              na łokciach, a jego ciało przeszył ból. Zawył, nawet nie zdając sobie sprawy, że krzyczy.
              – Spencer, dobrze mieć cię tu ponownie – oznajmił miły, lecz nieznany mu głos.
              – Przecież nigdzie się nie wybierałem – odparł Tremblay sarkastycznie.
              Powoli sobie uświadamiał, że jest w szpitalu. Dopiero gdy odwrócił głowę, dostrzegł
              młodą pielęgniarkę, która nalewała mu wody.
              – Czemu jestem w szpitalu?
              Zaczynał się denerwować, a maszyna wydająca irytujące dźwięki znowu dała o sobie
              znać.

              – Proszę, połóż się, zawołam lekarza – odezwała się kobieta, po czym wyszła z
              pomieszczenia.
              Spencer rozejrzał się w poszukiwaniu telefonu. Musiał skontaktować się z Alice i
              zapytać, co się stało. Czy doszło do jakiegoś wypadku i dlaczego jej tu nie ma, przecież jest
              jego asystentką.
              – Ja pierdolę, co to za ból?
              Wykrzywił się, mamrocząc pod nosem. Po chwili zaskoczył go głos lekarza
              wchodzącego do pomieszczenia.
              – Dzień dobry, jestem doktor Elliot Joy. Dobrze znów cię widzieć.
              O co im wszystkim chodziło? Przecież był tutaj cały czas.
              – Gdzie są moi ludzie, co się wydarzyło? Mogę odpiąć to piszczące coś? Do szału
              mnie doprowadza!
              – Faktycznie charakterny, tak jak piszą w mediach – zaśmiał się lekarz do pielęgniarki
              stojącej obok. – Spokojnie, Spencer, wszystko ci wytłumaczymy. Najpierw, proszę, pozwól
              nam przeprowadzić niezbędne badania.
              Doktor nachylił się ku pacjentowi, patrząc w jego oczy, jakby był nowym
              eksperymentem medycznym. Wyciągnął długopis i kazał skupić na nim wzrok.
              – Czy długopis jest dalej w twoim polu widzenia?
              – Przecież go odsunąłeś, więc już go nie widzę. – Spencer był mocno urażony, a
              mężczyzna w białym fartuchu coś zanotował.
              – Widzisz moje palce?
              Jego dłoń przesuwała się powoli, a za nią podążał wzrok Tremblaya, jednak w
              pewnym momencie dłoń medyka rozmazała się. Spencer zdał sobie sprawę, że jego wzrok się
              pogorszył.
              – Odmawiam dalszych eksperymentów na mnie! Czy ktoś łaskawie powie mi, co ja
              tutaj robię?!
              I znów pojawił się ten przeszywający ból. Spencer nakrył dłonią usta, próbując
              powstrzymać nadciągające wymioty, jednak mu się nie udało. Pielęgniarka wezwała kogoś do
              posprzątania bałaganu, który zrobił.
              – Witamy w świecie żywych – odezwał się ktoś stojący z boku.
              Spencer rozpoznał głos Jeremiego Smitha. Uniósł wzrok, jednak nadal trwał w
              przechylonej pozycji, w razie gdyby znów miały go dopaść wymioty.
              – O co wam wszystkim, kurwa, chodzi? – wymamrotał.

              – Czy pan doktor może wrócić później? Uspokoję nieco pacjenta – zażartował Jeremi,
              ciesząc się z tego, że Spencer, mimo przedawkowania, wciąż bywa sarkastyczny.
              – Proszę dawkować informacje, jest jeszcze w szoku – poinformował lekarz, po czym
              wyszedł z pomieszczenia.
              Jeremi był ubrany jak zawsze – w dopasowany, szyty na miarę elegancki garnitur.
              Usiadł naprzeciwko Spencera na małym plastikowym krześle, właściwie rozsiadł się w
              rozkroku i przeczesał dłonią idealnie ułożone włosy. Tremblay nie kwapił się do tego, by
              kolejny raz zadać to samo pytanie. Uniósł tylko pytająco brwi i czekał na wyjaśnienia.
              – Jak się czujesz? – zapytał agent.
              – A jak myślisz? – Spencer spojrzał w miejsce, w którym wcześniej były jego
              wymiociny.
              Po twarzy Jeremiego przebiegł uśmiech.
              – Wracasz do zdrowia, sądząc po twoich tekstach.
              – Może jakieś konkrety? – Tremblay zaczynał się irytować. – Co się stało?
              – Nic nie pamiętasz. – Agent zmrużył oczy.
              – Czy gdybym pamiętał, to bym pytał? – Spencer przewrócił oczami, po czym opadł
              na poduszkę, a ból przypomniał mu o jego egzystencji.
              – Przedawkowałeś – stwierdził Jeremi krótko i dosadnie.
              Spencer oniemiał. Przez chwilę kręcił przecząco głową.
              – Niemożliwe, nie biorę tego gówna od przeszło dwóch lat – wyrzucił z siebie
              arogancko.
              – Spójrz na siebie.
              W tych słowach czuć było smutek. Spencer wykonał zatem polecenie, rozejrzał się też
              po pomieszczeniu. Fakt, że znajdował się w szpitalu, ponownie zaczął do niego docierać, tak
              jakby wcześniej traktował to tylko jak zły sen. Zapadła niezręczna cisza.
              – Gdzie Alice? Muszę z nią porozmawiać – powiedział po chwili.
              – Zwolniłem ją – oznajmił krótko jego agent, wiercąc się na krześle.
              – Jak to ją zwolniłeś? – Podopieczny spojrzał na niego z niedowierzaniem.
              – Miała nie dopuścić do tego, co się wydarzyło.
              – A co się tak właściwie wydarzyło?!
              – Dobre pytanie. Spencer, co się wydarzyło? Wyszedłeś z premiery, mówiąc, że
              czujesz ból brzucha. Całe szczęście, że Alice była na tyle uparta, by za tobą pojechać. Gdyby
              znalazła cię kilka minut później, to nie spotkalibyśmy się tu, tylko na twoim pogrzebie.
              Usta otwarły mu się z niedowierzania. To brzmiało tak niewiarygodnie.

              – Ściągnij ją tu! Muszę z nią pilnie porozmawiać!
              – Nie wiem czy to dobry pomysł.</p>
              </div>
          </div>

          <div className={`accordion__item ${activeIndex === 2 ? 'active' : ''}`} onClick={() => handleAccordionClick(2)}>
            <div className="accordion__title p__opensans_about">"CICHA ARIA"</div>
            <div className="accordion__content p__opensans_about">
              <h2>Rozdział 1.</h2><br/>
              Potarł nagie ramiona dłońmi, gdy chłód znad oceanu przyprawił go o gęsią skórkę. Na
              zewnątrz robiło się coraz ciemniej i mroczniej, jednak Nataniel nie zamierzał wracać do
              domu. Jeszcze nie. O tej porze na plaży towarzyszyły mu tylko cisza i odgłosy ptaków.
              Kochał widok fal rozbijających się o brzeg; w jakiś dziwny i niezrozumiały sposób bardzo go
              to koiło. Jakby każda z nich symbolizowała jego wewnętrzne cierpienie.
              Spojrzał na deskę surfingową wbitą w piach. Ręce niemal się paliły, by ją chwycić i
              wbiec do wody, jednak to wiązałoby się prawdopodobnie z jednym. Ze śmiercią. Brak
              widoczności, wysokie fale… Wystarczyłby drobny błąd, a ocean pokazałby swoją siłę,
              wciągając ciało w ciemną toń. Dlatego to było takie kuszące – surfowanie to walka z
              żywiołem. Bitwa z nieznanym. Falę możesz okiełznać, ale ciężar wody, która cię za sobą
              ciągnie na dno, już nie.
              Chłopak otrzepał dłonie z piasku, podniósł się i zaczął zdejmować piankowy strój, który
              miał nasunięty do pasa, by przebrać się w podkoszulek i spodenki, nim wróci do domu. Nie
              chciał tam iść, do tego perfekcyjnego z pozoru życia, jakie musiał wieść. Plaża i surfowanie
              były jedyną ucieczka od niego. Tu był tylko on, a do tego woda, piasek i wysiłek, który
              musiał włożyć w utrzymanie się na kolejnej dzikiej tafli.
              Gdy nasunął na siebie trochę wilgotny podkoszulek, usłyszał jakieś wołanie. Najpierw
              je zignorował, myśląc, że to może bijące się o resztki ryby nadmorskie ptaki, jednak kilka
              sekund później usłyszał wyraźne „Pomocy!”. Chwycił telefon, włączył latarkę i pobiegł w
              kierunku wzburzonej wody.
              – Jest tam ktoś?! – Rozglądał się nerwowo.
              – Tutaj!
              Dostrzegł w wodzie czyjąś głowę, a tuż obok rękę wyciągniętą w górę. Ktoś próbował
              się utrzymać na powierzchni.
              Bez zastanowienia ruszył przed siebie, choć zdawał sobie sprawę, czym grozi
              wchodzenie do tak wzburzonego oceanu. Chłód momentalnie przeszył jego ciało, ale
              zignorował to, bo ręka, która przed chwilą energicznie machała, coraz bardziej zanurzała się
              w wodzie. Natanielowi wydawało się, że minęła wieczność, nim dotarł do dziewczyny, która
              wołała o pomoc. Złapał ją pod pachy i zorientował się, że zdążyła już stracić kontakt ze
              światem. Uderzyła w nich kolejna fala, przez co dotarło do niego, że teraz i on walczy o
              przeżycie. Musiał zachować zdrowy rozsądek, chociaż panika, która właśnie się pojawiła,
              zaczynała wygrywać. Nie wiedział, czy to przypływ adrenaliny i chęć przetrwania uruchomiły

              w nim jakąś nadludzką siłę, ale udało mu się ruszyć w stronę brzegu. Gdy dotarł do takiej
              wysokości wody, że sięgała mu do pępka, stanął i wziął dziewczynę na ręce.
              – Nie dziś! – rzucił zdyszany w stronę oceanu, usatysfakcjonowany, że udało mu się
              wyrwać z jego szponów.
              Wybiegł na brzeg i położył dziewczynę na piasku. Jej skóra była już sina od zimna, a
              ona nie dawała znaków życia. Nachylił się i sprawdził, czy oddycha, jednak jej klatka
              piersiowa się nie poruszała. Przystąpił do resuscytacji, choć nie był do końca pewny, czy
              wykonuje ją poprawnie.
              – Ja pierdolę! Nie umieraj, nie tutaj, nie przy mnie!
              Uciskał klatkę piersiową, rozglądając się za kimkolwiek, kto mógłby pomóc. Szukał też
              wzrokiem telefonu, ale go nie widział. Prawdopodobnie zginął podczas akcji ratowniczej, a
              Nate zrozumiał, że jest zdany sam na siebie.
              – Pomocy! – krzyknął najgłośniej, jak potrafił. Z jego gardła wydostał się nieznany mu
              dotychczas ryk.
              Ucisnął mocniej klatkę piersiową dziewczyny raz jeszcze, a ona nagle zaczęła
              wypluwać wodę.
              – Hej, hej. – Dotknął jej policzka, widząc zamglone oczy. – Proszę, nie opuszczaj mnie.
              – rzekł błagalnie.
              Dziewczyna odwróciła się na bok i wypluła resztkę wody zalegającej w płucach.
              Dopiero teraz Nataniel dostrzegł, kogo uratował. Przytulił ją do swojego torsu, próbując w ten
              sposób jakoś ogrzać, mimo że jego ciało również drżało.
              Migające światełko, z którymś ktoś biegł w ich stronę, rozproszyło jego uwagę.
              Chłopak ponownie zaczął krzyczeć, choć już resztkami sił.
              – Tutaj! Proszę wezwać pomoc!
              Ledwo przytomna dziewczyna spojrzała na niego, a jej twarz wyrażała szok i
              niezrozumienie. Nim ponownie opadła z sił i straciła przytomność, zdążyła wymamrotać
              bezsilnym głosem:
              – Ian?
              Na swojej skórze Nataniel poczuł dreszcze, niezwiązane z aktualną sytuacją, lecz z
              wiedzą, z kim miał w tamtym momencie do czynienia. Nieznajomy przechodzień właśnie do
              nich dotarł, prawdopodobnie rozmawiając już z kimś ze służby ratunkowej. Chłopak
              ponownie spojrzał na twarz dziewczyny, którą okalały mokre włosy.
              – Wszystko będzie dobrze – wyszeptał jej do ucha, licząc, że tak właśnie się stanie.

              ***

              Nate siedział na szpitalnym korytarzu, opatulony w jakiś materiał, który miał zapobiec
              wychłodzeniu organizmu. O tej porze snuły się tam tylko pielęgniarki. W sumie nie wiedział,
              jak powinien się zachować: czy czekać na jakieś wiadomości o stanie dziewczyny, czy może
              wrócić do domu? Wiedział, że żyła, i to było najważniejsze. Bił się z myślami,
              usprawiedliwiając swoje ewentualne wcześniejsze wyjście ze szpitala faktem, że rodzice
              pacjentki zostali już poinformowani o wypadku. Spojrzał na swój wodoodporny smartwatch
              wskazujący późną godzinę i datę – siódmy sierpnia. Dzisiejsze wydarzenie to na pewno nie
              był przypadek. W tym przekonaniu utwierdziło go wejście pana Robertsona do szpitala.
              Najpierw jego twarz wyrażała przerażenie spowodowane wypadkiem córki, gdy jednak
              dostrzegł Nataniela, zmieniło się to w mgnieniu oka na zdenerwowanie. Mięśnie na
              pięćdziesięcioletniej twarzy mocno się napięły, a szczęka uwidoczniła. Luźne spodnie
              dresowe i zmięty podkoszulek oznaczały, że telefon ze szpitala najprawdopodobniej go
              obudził. Tom skierował się prosto w stronę Nataniela, którego całe ciało wręcz krzyczało, że
              nie panuje nad swoimi emocjami.
              – Ty! – Robertson skierował palec wskazujący na chłopaka. – Zawsze zwiastujecie
              problemy. – Ślina wypadała mu z ust, gdy mówił podniesionym głosem.
              Nataniel podniósł się z fotela, chcąc pokazać, że nie boi się mężczyzny, chociaż
              wiedział, że Tom należy do tych nieobliczalnych. Uniósł dłonie w geście kapitulacji, a folia
              termoizolacyjna zsunęła się na podłogę.
              – To nie tak, jak pan myśli – zaczął delikatnie, jednak mężczyzna nie zamierzał go
              słuchać. Chwycił go za szyję i przycisnął do ściany tak mocno, że chłopakowi momentalnie
              zabrakło tchu.
              Nataniel instynktownie chwycił nadgarstki atakującego, by się uwolnić z morderczego
              uścisku.
              – Jeszcze raz któryś z pieprzonych Reedów zbliży się do mojej rodziny, a skończy w
              trumnie!
              Tom patrzył, jak twarz chłopaka staje się coraz bardziej czerwona, a jego oczy wręcz
              błagają o uwolnienie.
              – Panie Robertson, proszę go puścić, bo zaraz będę musiała wezwać ochronę! –
              krzyknęła pielęgniarka.
              Mężczyzna posłusznie rozluźnił chwyt, chociaż jego twarz wyrażała rozczarowanie.
              Nataniel osunął się na podłogę, łapiąc łapczywie powietrze i kaszląc, a z oczu ciekły mu łzy.

              – Mogę w czymś pomóc? – dopytała kobieta, kładąc dłonie na biodrach, co było
              wyrazem niezadowolenia.
              – Szukam córki, Brook Robertson.
              Pielęgniarka początkowo zignorowała mężczyznę i pośpiesznym krokiem podeszła
              prosto do Nataniela, upewniając się, że nic mu nie grozi. Dopiero po chwili uniosła wzrok na
              Toma.
              – W takim razie powinien pan podziękować chłopakowi za wyłowienie jej z lodowatej
              wody – odparła oschle.
              Robertson spojrzał na chłopaka, którego przed chwilą dusił. Nate wciąż siedział na
              podłodze, teraz opierając się o zimną ścianę i tylko patrząc z pogardą na ojca dziewczyny.
              Przez jakiś czas trzymał się jedną ręką za szyję, po czym, wciąż łapiąc powietrze, odgarnął
              część grzywki opadającej mu na oczy. Miał wrażenie, że krew odpłynęła Tomowi z twarzy.
              Mężczyzna powędrował językiem po swoich zębach i w milczeniu skierował wzrok na
              pielęgniarkę. Ta pokiwała głową z dezaprobatą.
              – Tędy, panie Robertson.
              Nataniel zebrał się w sobie, by krzyknąć za oddalającymi się:
              – Dziś rocznica, myślisz, że to przypadek?!
              Tom na chwilę się zatrzymał. Nie odwrócił się jednak do chłopaka, który właśnie
              podnosił się z podłogi, tylko po chwili namysłu ponownie ruszył w stronę pomieszczenia,
              gdzie miała się znajdować jego córka.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Read;
