import images from './images';

const wines = [
  {
    title: 'Chapel Hill Shiraz',
    price: '$56',
    tags: 'AU | Bottle',
  },
  {
    title: 'Catena Malbee',
    price: '$59',
    tags: 'AU | Bottle',
  },
  {
    title: 'La Vieillw Rose',
    price: '$44',
    tags: 'FR | 750 ml',
  },
  {
    title: 'Rhino Pale Ale',
    price: '$31',
    tags: 'CA | 750 ml',
  },
  {
    title: 'Irish Guinness',
    price: '$26',
    tags: 'IE | 750 ml',
  },
];

const cocktails = [
  {
    title: 'Aperol Sprtiz',
    price: '$20',
    tags: 'Aperol | Villa Marchesi prosecco | soda | 30 ml',
  },
  {
    title: "Dark 'N' Stormy",
    price: '$16',
    tags: 'Dark rum | Ginger beer | Slice of lime',
  },
  {
    title: 'Daiquiri',
    price: '$10',
    tags: 'Rum | Citrus juice | Sugar',
  },
  {
    title: 'Old Fashioned',
    price: '$31',
    tags: 'Bourbon | Brown sugar | Angostura Bitters',
  },
  {
    title: 'Negroni',
    price: '$26',
    tags: 'Gin | Sweet Vermouth | Campari | Orange garnish',
  },
];

const awards = [
  {
    imgUrl: images.award01,
    title: 'ZACZYTANA0598',
    subtitle: 'Natalia K. Palonek napisała tą powieść prostym językiem, a zbudowane napięcie i niepewność sprawiły, że książkę czytało się przyjemnie i z zapartym tchem. Serdecznie polecam twórczość tej autorki i liczę, że już niebawem będziemy mogli przeczytać kolejną jej książkę.',
  },
  {
    imgUrl: images.award02,
    title: 'AMERCINAN_BOOKS',
    subtitle: 'Podsumowując, debiut Natalii K. Palonek to bardzo dobry start w literacki świat. Ciekawi bohaterowie, nietuzinkowa historia, mroczne oblicze życia na świeczniku i intrygująca fabuła to elementy budujące całą historię. Jeżeli szukacie odskoczni od romansu i chcielibyście zagłębić się w dobrą powieść obyczajową, zdecydowanie polecam wam książkę Natalii. –',
  },
  {
    imgUrl: images.award03,
    title: 'PATRYCJA',
    subtitle: 'Bardzo dojrzała i wartościowa lektura z słodko gorzkim zakończeniem. Nie potrafię powiedzieć czy polubiłam bohaterów, gdyż są oni strasznie smutni i chłodni...niczym dwie bryły lodu. Książkę czyta się z wielkim zainteresowaniem, zaangażowaniem. Jest pełna refleksji i ciężkiej prawdy, która finalnie jest dla niektórych nie do zniesienia... Polecam wszystkim, którzy cenią aspekty psychologiczne i moralne. To piękna lekcja pokory.',
  },
  {
    imgUrl: images.award04,
    title: 'ANETTSKU',
    subtitle: 'Genialny pomysł na fabułę, akcja trzymająca w napięciu, nietuzinkowi bohaterowie, czyż może być lepiej ? Książka ta wywołała we mnie wiele emocjii, a po jej przeczytaniu musiałam ochłonąć. Powiem szczerze ,że zakończenie całkowicie mnie zaskoczyło bo nie tego się spodziewałam. Autorka zagrała ma moich uczuciach koncertowo i za to ja uwielbiam.',
  },
  {
    imgUrl: images.award05,
    title: 'KSIĄŻKOPASJA',
    subtitle: 'CICHA ARIA to już trzecia książka Autorki, jak zawsze pod przykrywką rozrywkowych tematów Natalia K. Palonek porusza też trudne wątki i społeczne problemy. Tym razem będzie to: eutanazja, walka o życie, manipulacja, korupcja, zastraszanie, tajemnice z przeszłości. Już od początku zapowiedzi bardzo zaintrygowała mnie okładka, a teraz zaskoczyło również wnętrze. Znam poprzednie tytuły i wiem, że Autorka ma przyjemny styl pisania, książki czyta się szybko i z zaciekawieniem.',
  },
  {
    imgUrl: images.award06,
    title: 'JUSTYNA',
    subtitle: 'Bardzo polecam książkę ,,Cicha Aria”. Niesamowita historia ,która wciąga od pierwszych stron. Okładka jest po prostu fenomenalna i adekwatna do treści. Wzbudza zainteresowanie, ciekawość tak samo jak historia. Bardzo podobają mi się postacie, każdy na swój sposób inny a szczególnie Nataniel z którym można się utożsamiać pod wieloma względami. Mogę polecić każdemu tą pozycję, czyta się to świetnie, trzyma w napięciu i dawno nie czytałam tak poruszającej historii.',
  },
];

export default { wines, cocktails, awards };
