import React from 'react';
import {images} from '../../constants';
import { SubHeadingWhite } from '../../components';
import { BsInstagram, BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import './Gallery.css';

const galleryImages = [images.gallery01, images.gallery02, images.gallery03, images.gallery04]

const Gallery = () => { 
  const scrollRef = React.useRef(null);
  const scroll = (direction) => {
    const {current} = scrollRef;

    if(direction === 'left'){
      current.scrollLeft -= 300;
    } else{
      current.scrollLeft +=300;
    }
  }

  return (
    <div className='app__gallery flex__center' id='gallery'>
      <div className='app__gallery-content'>
        <SubHeadingWhite title="Instagram" />
        <h1 className='app__header-h2'>Galeria</h1>
        <p className='p__opensans' style={{color: '#AAA', marginTop: '2rem'}}>Witajcie w magicznym świecie słów i historii! Tutaj znajdziesz wszystko, co związane z moim pisarskim życiem - od kulisy tworzenia po inspiracje i refleksje. Zapraszam Cię do podróży przez moje światy, gdzie marzenia stają się rzeczywistością.</p>
        <button type='button' className='custom__button'><a href="https://www.instagram.com/nataliapalonek/">Odwiedź profil</a></button>
      </div>
      <div className='app__gallery-images'>
        <div className='app__gallery-images_container' ref={scrollRef}>
          {galleryImages.map((image, index) => (
            <div className='app__gallery-images_card flex__center' key={'gallery_image-${index+1}'}>
              <img src={image} alt="gallery image" />
              <BsInstagram className='gallery__image_icon' />
            </div>
          ))}
        </div>
        <div className='app__gallery-images_arrows'>
          <BsArrowLeftShort className='gallery_arrow-icon' onClick={() => scroll('left')} /> 
          <BsArrowRightShort className='gallery_arrow-icon' onClick={() => scroll('right')} /> 
        </div>
      </div>
    </div>
  );
}

export default Gallery;
