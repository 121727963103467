import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdClose, MdOutlineRestaurantMenu } from 'react-icons/md';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

import images from '../../constants/images';

import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  return (
  <nav className='app__navbar'>
    <div className='app__navbar-logo'>
      <img src={images.logo} alt="app logo" />
    </div>
    <ul className='app__navbar-links'>
      <li className='p__opensans'><a href='#home'>Home</a></li>
      <li className='p__opensans'><a href='#about'>O mnie</a></li>
      <li className='p__opensans'><a href='#books'>Książki</a></li>
      <li className='p__opensans'><a href='#read'>Przeczytaj</a></li>
      <li className='p__opensans'><a href='#testimonials'>Opinie</a></li>
      <li className='p__opensans'><a href='#books'>Kup</a></li>
      <li className='p__opensans'><a href='#gallery'>Instagram</a></li>
      <li className='p__opensans'><a href='#contact'>Kontakt</a></li>
    </ul>
      <div className='app__navbar-links_icons'>
        <li><a href="https://www.facebook.com/profile.php?id=100083056974359"><FaFacebook /></a></li>
        <li><a href="https://www.instagram.com/nataliapalonek/"><FaInstagram /></a></li>
        <li><a href="https://www.linkedin.com/in/natalia-palonek-7771ab18b/"><FaLinkedin /></a></li>
      <div/>
    </div>
    <div className='app__navbar-smallscreen'>
      <GiHamburgerMenu color='#fff' fontSize={27} onClick={()=>setToggleMenu(true)}/>
      {toggleMenu && (
      <div className='app__navbar-smallscreen_overlay flex__center slide'>
        <MdClose fontSize={27} className='overlay__close' onClick={()=>setToggleMenu(false)}/>
        <ul className='app__navbar-smallscreen_links'>
          <li><a href="#home" onClick={() => setToggleMenu(false)}>Home</a></li>
          <li><a href="#about" onClick={() => setToggleMenu(false)}>O mnie</a></li>
          <li><a href="#books" onClick={() => setToggleMenu(false)}>Książki</a></li>
          <li><a href="#read" onClick={() => setToggleMenu(false)}>Przeczytaj</a></li>
          <li><a href="#testimonials" onClick={() => setToggleMenu(false)}>Opinie</a></li>
          <li><a href="#gallery" onClick={() => setToggleMenu(false)}>Instagram</a></li>
          <li><a href="#contact" onClick={() => setToggleMenu(false)}>Kontakt</a></li>
        </ul>
      </div>
      )}
    </div>
  </nav>
  )
}

export default Navbar;
