import React from 'react';
import images from '../../constants/images';

const SubHeading = ({title}) => (
  <div style={{marginBottom: '1rem'}}>
    <div><p className='p__opensans2'>{title}</p></div>  
    <div><img src={images.separatorblack} alt='separatorblack' className='separator__img'/></div>
  </div>
);

export default SubHeading;
