import React from 'react';
import { useEffect } from 'react';
import images from '../../constants/images';
import { SubHeading } from '../../components';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './SPWP.css';


const AboutUs = () => {
  useEffect(() => {
    AOS.init({duration: 2000, disable: 'mobile'});
  }, []) 
return (
  <div className='app__bg app__wrappe' id='spwp'>
    <div className='spwp_image'>
      <img className='spwp_bg' src={images.SPWP} alt="spwp" />
      <div className='app__wrapper_info_text_cover'>
        <SubHeading title="MOJA NAJNOWSZA KSIĄŻKA" />
          <div className='app__text_cover'>
            <p className='p__opensans_about_spwp'>
              Ziemia skrywa więcej sekretów, 
              niż możesz sobie wyobrazić.
              Odkryj to, co miało pozostać pogrzebane
              na zawsze... albo prawie na zawsze
            </p>
            <button type='button' className='custom__button'><a href='https://www.empik.com/sekrety-pogrzebane-w-popiele-natalia-k-palonek,p1547192018,ksiazka-p'>Kup</a></button>    
          </div>
      </div>
    </div>
  </div>
);
}

export default AboutUs;
