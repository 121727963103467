import React from 'react';
import { useEffect } from 'react';
import images from '../../constants/images';
import { SubHeading } from '../../components';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './AboutUs.css';


const AboutUs = () => {
  useEffect(() => {
    AOS.init({duration: 2000, disable: 'mobile'});
  }, []) 
return (
  <div className='app__bg app__wrapper section__padding' id='about'>
  <div className='app__wrapper_img app__wrapper_img_reverse' data-aos="fade-right">
    <img src={images.about_bg2} alt="chef" />
  </div>
  <div className='app__wrapper_info' data-aos="fade-left">
    <SubHeading title="Kilka słów o mnie" />
    <h1 className='app__header-h2'>Kim jestem?</h1>
    <div className='app__chef-content'>
      <div className='app__chef-content_quote'>
        <p className='p__opensans_about'>Pochodzę z małej miejscowości w województwie małopolskim.
        Obecnie pracuje w dziale finansów w jednej z Krakowskich korporacji. Prywatnie szczęśliwa
        żona. Wielbicielka podróży małych i dużych, fanka muzyki rockowej, miłośniczka zwierząt.
        Uparcie wyznacza sobie nowe cele, które zamierza zrealizować.
        Pasję do pisania odkryła w liceum, najpierw pisząc krótkie teksty, które powoli przeradzały
        się w wielostronicowe opowiadania.
        Autorka książek „Dziewczyna do towarzystwa”, „Nieustanna Gra Pozorów” oraz „Cicha
        Aria”.</p>
      </div>
    </div>
    <div className='app__chef-sign'>
      <img src={images.sign} alt="sign" />
    </div>
  </div>
</div>
);
}

export default AboutUs;
